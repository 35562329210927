<template>
  <div class="text-end mt-2 mb-2">
    <button class="myButtonAceptar" @click="agregarSlider">Agregar</button>
  </div>

  <div class="card table-responsive-sm">
    <table class="table table-striped table-hover" v-if="listSlider">
      <thead>
        <tr>
          <th scope="col">Titulo</th>
          <th scope="col">Hora inicio</th>
          <th scope="col">Fecha inicio</th>
          <th scope="col">Hora fin</th>
          <th scope="col">Fecha fin</th>
          <th scope="col">Imagen</th>
          <th scope="col">Estatus</th>
          <th scope="col">Orden</th>
          <th scope="col">Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="s of listSlider.data" v-bind:key="s.id">
        <tr style="cursor: pointer">
          <td @click="select(s)">{{ s.titulo }}</td>
          <td @click="select(s)">{{ timeFormat(s.fecha_ini) }}</td>
          <td @click="select(s)">{{ dateFormat(s.fecha_ini) }}</td>
          <td @click="select(s)">{{ timeFormat(s.fecha_fin) }}</td>
          <td @click="select(s)">{{ dateFormat(s.fecha_fin) }}</td>
          <td @click="select(s)"><img height="50" :src="getImg(s.imgp)" /></td>
          <td @click="select(s)">{{ validarStatus(s.status) }}</td>
          <td @click="select(s)">{{ s.orden }}</td>
          <td>
            <button class="myButton" @click="eliminarSlider(s)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <Paginador      
      :data="listSlider.links"
      endpoint="/api/v1/getSlider"
      set="setSlider"
    /> -->
  </div>
</template>

<script>
import { inject } from "vue-demi";
import useUtil from "@/use/useUtil.js";
import Swal from "sweetalert2";
import { useStore } from "vuex";
// import Paginador from "../../../components/Paginador.vue";
import useSlider from "../composable/useSlider";
export default {
  props: ["listSlider"],
  components: {
    // Paginador,
  },
  setup() {
    const { dateFormat, timeFormat, dateFormatInput, timeFormatInput, getImg } =
      useUtil();
    const objSlider = inject("objSlider");
    const btn = inject("btn");
    const imageUrl = inject("imageUrl");
    const imageUrlp = inject("imageUrlp");
    const store = useStore();
    const { getSlider, deleteSlider } = useSlider();

    const agregarSlider = () => {
      objSlider.id = null;
      objSlider.titulo = "";
      objSlider.orden = 1;
      objSlider.status = true;
      objSlider.fecha_ini = null;
      objSlider.fecha_fin = null;
      objSlider.img = null;
      objSlider.imgp = null;
      btn.value = true;
      imageUrl.value = null;
      imageUrlp.value = null;
    };

    const select = (e) => {
      objSlider.id = e.id;
      objSlider.titulo = e.titulo;
      objSlider.status = e.status;
      objSlider.orden = e.orden;
      objSlider.fecha_ini =
        dateFormatInput(e.fecha_ini) + "T" + timeFormatInput(e.fecha_ini);
      objSlider.fecha_fin =
        dateFormatInput(e.fecha_fin) + "T" + timeFormatInput(e.fecha_fin);
      objSlider.img = e.img;
      objSlider.imgp = e.imgp;
      // imageUrl.value = e.img ? process.env.VUE_APP_URL_IMG + e.img : null;
      // imageUrlp.value = e.imgp ? process.env.VUE_APP_URL_IMG + e.imgp : null;
      btn.value = true;      
    };

    const validarStatus = (status) => (status ? "Mostar" : "Ocultar");

    const eliminarSlider = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objSlider.id = e.id;

          store.commit("showLoader");
          await deleteSlider(objSlider);
          await getSlider({ perPage: 5 });
          store.commit("hideLoader");

          return true;
        }
      });
    };

    return {
      dateFormat,
      timeFormat,
      agregarSlider,
      select,
      validarStatus,
      getImg,
      eliminarSlider,
    };
  },
};
</script>

<style scoped>
.myButton {
  box-shadow: inset 0px 1px 0px 0px #f29c93;
  background: linear-gradient(to bottom, #fe1a00 5%, #ce0100 100%);
  background-color: #fe1a00;
  border-radius: 6px;
  border: 1px solid #d83526;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 4px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #b23e35;
}
.myButton:hover {
  background: linear-gradient(to bottom, #ce0100 5%, #fe1a00 100%);
  background-color: #ce0100;
}
.myButton:active {
  position: relative;
  top: 1px;
}
</style>