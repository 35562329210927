<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Slider</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <SliderTables :listSlider="listSlider" />
      </div>
      <div v-if="btn" class="col-12"><SliderForm /></div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, provide, reactive } from "vue-demi";
import SliderForm from "../components/SliderForm.vue";
import SliderTables from "../components/SliderTables.vue";
import { useStore } from "vuex";
import useSlider from "../composable/useSlider";

export default {
  name: "Slider",
  components: {
    SliderForm,
    SliderTables,
  },
  props: ["cajas"],
  setup() {
    const store = useStore();
    const btn = inject("btn");
    const { getSlider, listSlider } = useSlider();
    const boxSlider = reactive({
      activo: 0,
      inactivo: 0,
      total: 0,
      titulo: "Slider",
    });
    const objSlider = reactive({
      id: null,
      titulo: "",
      orden: 1,
      status: true,
      fecha_ini: null,
      fecha_fin: null,
      img: null,
      imgp: null,
      deleted: false,
      tipo:"imagen"
    });

    onMounted(async () => {
      store.commit("showLoader");
      await getSlider({ perPage: 5 });          
      store.commit("hideLoader");      
    });

    provide("objSlider", objSlider);
    return { listSlider, btn, boxSlider };
  },
};
</script>

<style>
</style>