<template>
  <form @submit.prevent="submitSlider" class="ayudasSlider">
    <div class="text-end text-light">
      <Cerrar />
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="" class="text-light">Titulo</label>
        <input
          type="text"
          v-model="objSlider.titulo"
          id="titulo"
          :class="{
            'form-control': true,
          }"
        />
        <small class="form-text txt-red" v-if="v$.titulo.$error">
          *Debe agregar un titulo.
        </small>
      </div>
      <div class="col-md-2">
        <label for="" class="text-light">Orden</label>
        <select name="" class="form-select" v-model="objSlider.orden">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="" class="text-light">Tipo</label>
        <select name="" class="form-select" v-model="objSlider.tipo">
          <option value="imagen">Imagen</option>
          <option value="video">Video</option>
        </select>
      </div>

      <div class="col-md-3">
        <label for="" class="text-light">Fecha y hora inicio</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="objSlider.fecha_ini"
        />
        <small class="form-text txt-red" v-if="v$.fecha_ini.$error">
          *Debe agregar una fecha y hora de inicio.
        </small>
      </div>
      <div class="col-md-3">
        <label for="" class="text-light">Fecha y hora fin</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="objSlider.fecha_fin"
        />

        <small class="form-text txt-red" v-if="v$.fecha_fin.$error">
          *Debe agregar una fecha y hora de fin.
        </small>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <label v-if="objSlider.tipo == 'imagen'" class="text-light"
          >Imagen web</label
        >
        <label v-if="objSlider.tipo == 'video'" class="text-light"
          >Video web</label
        >
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objSlider.img"
            @click="pegarImagen('img')"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            id="button-addon2"
            @click="limpiarInput('img')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('img')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
        <img
          :src="objSlider.img || 'img/no-img.jpg'"
          alt=""
          id="img"
          class="img-fluid img-slider mt-1"
          style="cursor: pointer"
        />
      </div>
      <div class="col-3">
        <label v-if="objSlider.tipo == 'imagen'" class="text-light"
          >Imagen movil</label
        >
        <label v-if="objSlider.tipo == 'video'" class="text-light"
          >Video movil</label
        >
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objSlider.imgp"
            @click="pegarImagen('imgp')"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            id="button-addon2"
            @click="limpiarInput('imgp')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('imgp')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>

        <img
          :src="objSlider.imgp || 'img/no-img.jpg'"
          alt=""
          id="img"
          class="img-fluid img-slider mt-1"
          style="cursor: pointer"
        />
      </div>
      <div class="col-md-3">
        <div class="form-check form-switch text-center">
          <input
            v-model="objSlider.status"
            class="form-check-input"
            type="checkbox"
            :checked="objSlider.status"
          />
          <label v-if="objSlider.status" class="form-check-label text-light"
            >Mostrar</label
          >
          <label v-else class="form-check-label text-light">Oculto</label>
        </div>
      </div>
    </div>
    <div class="row justify-content-end"></div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount, ref } from "vue-demi";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Cerrar from "../../../components/button/Cerrar.vue";
import useSlider from "../composable/useSlider";
import Swal from "sweetalert2";
import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
import useUtil from "../../../use/useUtil";
export default {
  components: {
    Cerrar,
    GaleriaBtn,
  },
  setup() {
    const btn = inject("btn");
    const objSlider = inject("objSlider");
    const imageUrl = inject("imageUrl");
    const imageUrlp = inject("imageUrlp");
    const previewImage = ref("");
    const store = useStore();
    const { createSlider, getSlider, editSlider } = useSlider();
    const { onPegarTexto } = useUtil();

    const cerrar = () => {
      btn.value = false;
    };

    const rules = {
      titulo: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objSlider);

    const format_ini = () => {
      const day = objSlider.fecha_ini.getDate();
      const month = objSlider.fecha_ini.getMonth() + 1;
      const year = objSlider.fecha_ini.getFullYear();
      const hora = objSlider.fecha_ini.toLocaleString("es-VE", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${day}/${month}/${year} ${hora}`;
    };
    const format_fin = () => {
      const day = objSlider.fecha_fin.getDate();
      const month = objSlider.fecha_fin.getMonth() + 1;
      const year = objSlider.fecha_fin.getFullYear();
      const hora = objSlider.fecha_fin.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${day}/${month}/${year} ${hora}`;
    };

    const submitSlider = async () => {
      v$.value.$touch();

      let resp = "";
      store.commit("showLoader");
      if (v$.value.$invalid) {
        console.log("ERRRR");
        return false;
      }
      if (objSlider.id) {
        resp = await editSlider(objSlider);
      } else {
        resp = await createSlider(objSlider);
      }
      await getSlider({ perPage: 5 });
      store.commit("hideLoader");
      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }

      cerrar();
    };

    const limpiarInput = (val) => {
      switch (val) {
        case "img":
          objSlider.img = "";
          break;

        case "imgp":
          objSlider.imgp = "";
          break;
      }
    };

    const pegarImagen = async (val) => {
      switch (val) {
        case "img":
          objSlider.img = "";
          objSlider.img = await onPegarTexto();
          break;
        case "imgp":
          objSlider.imgp = "";
          objSlider.imgp = await onPegarTexto();
          break;
      }
    };

    const onChange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        previewImage.value = e.target.result;
        objSlider.img = previewImage.value;
      };
      // image.value = file;
      imageUrl.value = URL.createObjectURL(file);
    };
    const onChangeP = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        previewImage.value = e.target.result;
        objSlider.imgp = previewImage.value;
      };
      imageUrlp.value = URL.createObjectURL(file);
    };

    onBeforeUnmount(() => {
      objSlider.id = null;
      objSlider.titulo = "";
      objSlider.orden = 1;
      objSlider.status = true;
      objSlider.fecha_ini = null;
      objSlider.fecha_fin = null;
      objSlider.img = null;
      objSlider.imgp = null;
      imageUrl.value = null;
      imageUrlp.value = null;
      btn.value = false;
    });

    return {
      cerrar,
      objSlider,
      format_ini,
      format_fin,
      submitSlider,
      imageUrl,
      onChange,
      onChangeP,
      imageUrlp,
      v$,
      limpiarInput,
      pegarImagen,
    };
  },
};
</script>

<style coped>
.ayudasSlider {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  /* width: 70em; */
  margin: auto;
}

@media (max-width: 1000px) {
  .ayudasSlider {
    width: 100%;
  }
}

.img-slider {
  width: 350px;
}
</style>