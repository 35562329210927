import { computed } from "vue";
import { useStore } from "vuex";

const useSlider = () => {
  const store = useStore();

  const createSlider = async (obj) => {
    const resp = await store.dispatch("slider/createSlider", obj);
    return resp;
  };
  const editSlider = async (obj) => {    
    const resp = await store.dispatch("slider/editSlider", obj);
    return resp;
  };
  const deleteSlider = async (obj) => {
    const resp = await store.dispatch("slider/deleteSlider", obj);
    return resp;
  };
  const getSlider = async (obj) => {
    const resp = await store.dispatch("slider/getSlider", obj);
    return resp;
  };

  return {
    createSlider,
    editSlider,
    deleteSlider,
    getSlider,
    listSlider: computed(() => store.getters["slider/getSlider"]),
  };
};

export default useSlider;
